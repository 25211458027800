import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Pagination} from "../model/pagination";
import {Observable} from "rxjs";
import {MyHttpParam} from "../model/http-param";

@Injectable({
    providedIn: 'root'
})
export class PaginationService<T> {

    constructor(private httpClient: HttpClient) {
    }

    getPagination(url: string, parameters: MyHttpParam[]): Observable<Pagination<T>> {
        const limitParam = parameters.find(it => it.name == 'limit')
        if (limitParam == undefined)
            parameters.push({name: 'limit', value: 50})


        let params = new HttpParams();
        parameters.forEach(it => params = params.append(it.name, it.value))
        return this.httpClient.get<Pagination<T>>(url, {params: params})
    }
}