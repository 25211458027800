import {Component, OnInit} from '@angular/core';
import {Issue} from "../model/issue";
import {IssueService} from "../service/issue/issue.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {AuthService} from "../service/auth/auth.service";
import {Pagination} from "../model/pagination";

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.css']
})
export class IssueComponent implements OnInit {

  userRoles: string[] | null = []
  issuePagination: Pagination<Issue> = <Pagination<Issue>>{}

  displayedColumns: string[] = ['title', 'description', 'contact_info', 'device_id', 'image', 'is_solved'];

  constructor(private _issuesService: IssueService, private _authService: AuthService) {
  }

  ngOnInit(): void {
    this._issuesService.getIssuesPagination(1)
      .subscribe(issues => {
        this.issuePagination = issues
      })
    this.userRoles = this._authService.getUserRoles()

  }

  solveIssue(issueId: string, $event: MatCheckboxChange) {
    if (!this.userRoles?.some(value => value == 'ADMIN')) {
      alert("You do not have admin privileges.")
      return
    }
    this._issuesService.setIssueStatus(issueId, $event.checked).subscribe()
  }

  getIssuePaginate($event: number) {
    this._issuesService.getIssuesPagination($event).subscribe(issues => {
      this.issuePagination = issues
    })
  }
}
