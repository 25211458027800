<div>
    <h1>Issues</h1>
</div>

<div *ngIf="issuePagination">

    <table mat-table [dataSource]="issuePagination.items" class="mat-elevation-z8 demo-table" #table>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element"> {{element.title}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="contact_info">
            <th mat-header-cell *matHeaderCellDef>Contact Info</th>
            <td mat-cell *matCellDef="let element"> {{element.contactInfo}} </td>

        </ng-container>

        <ng-container matColumnDef="device_id">
            <th mat-header-cell *matHeaderCellDef>Device ID</th>
            <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
        </ng-container>

        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef>Image</th>
            <td mat-cell *matCellDef="let element">
                <img src="{{element.image}}" alt="">
            </td>
        </ng-container>

        <ng-container matColumnDef="is_solved">
            <th mat-header-cell *matHeaderCellDef>Is Solved</th>
            <td mat-cell *matCellDef="let element">
                <!--        <input (click)="solveIssue(element.id, )" type="checkbox" [checked]="element.isSolved" [disabled]="element.isSolved">-->
                <mat-checkbox [checked]="element.isSolved" (change)="solveIssue(element.id, $event)"></mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <app-pager
            [totalItems]="issuePagination?.meta?.totalItems"
            (newItemEvent)="getIssuePaginate($event)">
    </app-pager>
</div>

